import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useLibAnalytics } from '@sparx/analytics';
import { Product } from '@sparx/api/apis/sparx/types/product';
import { LoadingSpinner } from '@sparx/sparx-design/icons/LoadingSpinner';
import { clickedCurrentHomework, clickedScienceStudentLogin } from 'app/events';
import { Header } from 'components/header';
import { Hero, HeroItem } from 'components/hero/hero';
import { LastLogin } from 'components/last-login/last-login';
import { Link } from 'components/link';
import { LinkCard } from 'components/link-card/link-card';
import { CurrentHomeworkCard } from 'components/maths/current-homework-card';
import { DeletedHomeworkCard } from 'components/maths/deleted-homework-card';
import { PreviousHomeworkCard } from 'components/maths/previous-homework-card';
import { ThemeWrapper } from 'components/theme-wrapper/theme-wrapper';
import { useScienceSummary } from 'queries/science';
import { useMemo } from 'react';
import { groupSciencePackages } from 'utils/packages';
import { SuspenseStudentName } from 'views/home-view/home-view';

import styles from './science-view.module.css';

export const ScienceView = () => {
  const sendEvent = useLibAnalytics();
  const { data, isLoading } = useScienceSummary();
  const school = useMemo(() => new URLSearchParams(window.location.search).get('school') || '', []);

  const { currentPackages, previousPackages } = useMemo(
    () => groupSciencePackages(data?.packages || [], true),
    [data?.packages],
  );

  return (
    <ThemeWrapper theme="parentweb-science">
      <Header to="/" />

      <Hero product={Product.SPARX_SCIENCE}>
        <HeroItem icon={faUser}>
          <SuspenseStudentName />
        </HeroItem>
        <HeroItem label="Last login">
          <LastScienceLogin />
        </HeroItem>
      </Hero>

      <section className={styles.Container}>
        <div className={styles.Body}>
          {isLoading && <LoadingSpinner size="lg" />}
          {currentPackages.map(pkg =>
            pkg.deletedTime ? (
              <DeletedHomeworkCard key={pkg.id} pkg={pkg} />
            ) : (
              <Link
                to={pkg.id.replace('packages/', '')}
                style={{ textDecoration: 'none' }}
                key={pkg.id}
              >
                <CurrentHomeworkCard
                  pkg={pkg}
                  onClick={() => sendEvent(clickedCurrentHomework(Product.SPARX_SCIENCE, pkg.id))}
                />
              </Link>
            ),
          )}
          {previousPackages && (
            <PreviousHomeworkCard
              product={Product.SPARX_SCIENCE}
              packages={previousPackages}
              onFocusPackage={() => undefined}
            />
          )}
          {/* <LinkCard icon={faComment} title="Give feedback" destination="/feedback" /> */}
          {/* <LinkCard icon={faQuestion} title="FAQs" destination="/faqs/science" /> */}
          <LinkCard
            icon={faUser}
            title="Student login"
            destination={`https://app.sparxscience.com?${new URLSearchParams({
              school,
            }).toString()}`}
            external={true}
            event={clickedScienceStudentLogin()}
          />
        </div>
      </section>
    </ThemeWrapper>
  );
};

const LastScienceLogin = () => {
  const { data } = useScienceSummary({ suspense: true });
  return <LastLogin timestamp={data?.lastLogin} />;
};
