import { faQuestion, faUser } from '@fortawesome/free-solid-svg-icons';
import { clickedReaderFAQs, clickedReaderStudentLogin } from 'app/events';
import classNames from 'classnames';
import { LinkCard } from 'components/link-card/link-card';

import styles from './links.module.css';

export const Links = () => {
  return (
    <div className={classNames(styles.Card, styles.NoBorder)}>
      {/*<LinkCard icon={faComment} title="Give feedback" destination="/feedback" />*/}
      <LinkCard
        icon={faQuestion}
        title="FAQs"
        destination="/faqs/reader"
        event={clickedReaderFAQs()}
      />
      <LinkCard
        icon={faUser}
        title="Student login"
        external={true}
        destination="https://app.sparxreader.com"
        event={clickedReaderStudentLogin()}
      />
    </div>
  );
};
