import {
  UserInteractionClientMessage,
  UserInteractionEvent,
} from '@sparx/api/genproto/apis/uievents/uievents';
import { Timestamp } from '@sparx/api/google/protobuf/timestamp';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import {
  loadClientEvents,
  makeLocalStorageKey,
  makeQueryKey,
  setClientEvents,
} from './localStorage';

declare let gtag: Gtag.Gtag;

// a pump is a hook which is responsible for batching and processing events which it receives.
// it returns a function which can be used to register events to be process with it.
// When the returned function is called, the event passed will be stored in local storage and the pump will be enabled.
// After 5 seconds the pump will send all stored messages, and the disable itself until more events are received.

/** useInteractionGatewayPump is a pump which sends metrics to interaction gateway. These messages end up in bigquery. */
export const useInteractionGatewayPump = (app: string, enabled: boolean, url?: string) => {
  const queryKey = makeQueryKey('interactionGateway');
  const localStorageKey = makeLocalStorageKey(app, 'interactionGateway');
  return usePump(
    queryKey,
    async () => {
      const request: UserInteractionClientMessage = {
        metrics: loadClientEvents(localStorageKey),
        timestamp: Timestamp.now(),
      };
      if (request.metrics.length > 0) {
        const response = await dispatchInteractionEvents(url || '', request);
        if (response.status !== 500) {
          setClientEvents(localStorageKey, []);
        }
        if (!response.ok) {
          throw response;
        }
      }
      return null;
    },
    localStorageKey,
    enabled,
  );
};

/**
 * dispatchInteractionEvents sends a batch of user interactions to the interaction gateway.
 */
export const dispatchInteractionEvents = (
  url: string,
  body: UserInteractionClientMessage,
  opts?: { keepalive?: boolean },
) =>
  fetch(url, {
    body: UserInteractionClientMessage.toJsonString(body),
    method: 'POST',
    mode: 'cors',
    ...opts,
  });

// even though the code within the query function is synchronous, this is a query so it can use the same pattern as others.
// todo: don't send these once there are a lot of users.
/** useGAPump is a pump which sends analytics to GA. */
export const useGAPump = (app: string, enabled: boolean, gaPropertyID?: string) => {
  let gtagAvailable = false;
  try {
    gtag;
    gtagAvailable = true;
  } catch {
    gtagAvailable = false;
  }
  const queryKey = makeQueryKey('ga');
  const localStorageKey = makeLocalStorageKey(app, 'ga');

  // send config to ga property
  useEffect(() => {
    if (!gtagAvailable) {
      return;
    }
    gtag('config', gaPropertyID || '', {
      application: app,
    });
  }, [app, gaPropertyID, gtagAvailable]);

  return usePump(
    queryKey,
    async () => {
      if (!gtagAvailable) {
        return null;
      }
      const metrics = loadClientEvents(localStorageKey);
      metrics.forEach(m => {
        if (m.action === pageChangeEventAction) {
          return;
        }
        const eventParams: Gtag.EventParams = {
          event_category: m.category,
          event_label: labelToString(m.labels),
        };
        gtag('event', m.action, eventParams);
      });
      setClientEvents(localStorageKey, []);
      return null;
    },
    localStorageKey,
    enabled,
  );
};

/** pageChangeEventAction is an action which will be ignored by the ga pump */
export const pageChangeEventAction = 'change page';

// labelToString converts a set of labels to a string for sending to ga
const labelToString = (label: Record<string, string>) =>
  Object.entries(label)
    .map(([property, value]) => property + ':' + value)
    .join(' ');

// usePump runs abstracts the common pump logic
const usePump = (
  queryKey: string,
  // queryFn cant return undefined, but shouldn't return anything, as its just thrown away.
  queryFn: () => Promise<null>,
  storageKey: string,
  enabled: boolean,
) => {
  const [hasNewEvents, setHasNewEvents] = useState(false);
  useQuery([queryKey], queryFn, {
    onSuccess: () => {
      setHasNewEvents(false);
    },
    refetchInterval: 5000,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: enabled && hasNewEvents,
    initialData: true,
  });

  // If not enabled, don't push the events to this pump
  if (!enabled) {
    return () => {};
  }

  return (event: UserInteractionEvent) => {
    const clientEvents = loadClientEvents(storageKey);
    clientEvents.push(event);
    setClientEvents(storageKey, clientEvents);
    setHasNewEvents(true);
  };
};
